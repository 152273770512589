<template>
    <v-container fluid>
        <v-form @submit.prevent="getClients('search')">
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <v-subheader class="headline">{{ $t('search_client') }}</v-subheader>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0" cols="12" sm="4">
                                <ValidationProvider ref="client" rules="min:1|max:255"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="client" type="text" :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-account"
                                                  :label="$t('client_name')" color="primary"
                                                  clearable></v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="4">
                                <ValidationProvider ref="phone" rules="phone"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="phoneRaw" type="tel" v-mask="['+# (###) ###-##-##', '+## (###) ###-##-##', '+### (###) ###-##-##']"
                                                  :error-messages="errors" :disabled="loading"
                                                  prepend-icon="mdi-phone" :label="$t('phone')"
                                                  color="primary" clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="4">
                                <ValidationProvider ref="email" rules="email|min:5|max:50"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="email" type="email" :error-messages="errors"
                                                  :disabled="loading"
                                                  prepend-icon="mdi-email" :label="$t('email')"
                                                  color="primary" clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>

                            <v-col class="py-0" cols="12" sm="4">
                                <ValidationProvider ref="sex"  rules="min:1"
                                                    v-slot="{ errors, valid }">
                                    <v-select v-model="sex" :items="sexItems" :disabled="loading" item-text="name"
                                              :error-messages="errors" item-value="id"
                                              prepend-icon="mdi-gender-male-female"
                                              :label="$t('sex')" clearable></v-select>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="3">
                                <ValidationProvider ref="ageFrom"
                                                    rules="min_value:14|max_value:100"
                                                    v-slot="{ errors, valid }">
                                    <v-select v-model="ageFrom" :items="ageFromItems" :disabled="loading"
                                              :error-messages="errors" prepend-icon="mdi-face-man"
                                              :label="$t('age_from')" color="primary"
                                              clearable></v-select>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="3">
                                <ValidationProvider ref="ageTo"
                                                    rules="min_value:14|max_value:100"
                                                    v-slot="{ errors, valid }">
                                    <v-select v-model="ageTo" :items="ageToItems" :disabled="loading"
                                              :error-messages="errors" prepend-icon="mdi-face-man"
                                              :label="$t('age_from')" color="primary"
                                              clearable></v-select>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="2">
                                <v-select v-model="active" :items="activeItems" :disabled="loading"
                                          item-text="name" item-value="id" prepend-icon="mdi-check"
                                          :label="$t('active')"></v-select>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="px-4">
                        <v-row>
                            <v-col cols="12" sm="3" md="2">
                                <v-select :value="perPage" :items="perPageItems" :disabled="loading"
                                          :label="$t('items_per_page')" prepend-icon="mdi-counter"
                                          @input="perPage = options.itemsPerPage = Number($event)"
                                          hide-details dense></v-select>
                            </v-col>
                            <v-col cols="12" sm="9" md="10"
                                   class="d-flex justify-center justify-sm-end align-self-center">
                                <v-btn type="submit" :disabled="invalid || loading"
                                       :block="$vuetify.breakpoint.xsOnly" color="primary">
                                    {{ $t('search') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
        <v-card flat>
            <v-card-text>
                <v-data-table :headers="filteredHeaders" :items="clients" :options.sync="options"
                              :page.sync="page" :items-per-page="perPage" :server-items-length="totalClients"
                              :sort-by.sync="sortBy" :sort-desc.sync="sortDir" :loading="loading" :locale="lang"
                              @page-count="pageCount = options.pageCount = Number($event)"
                              :loading-text="$t('loading_please_wait')" class="elevation-1"
                              hide-default-footer>
                    <template v-slot:top>
                        <v-col cols="12">
                            <v-btn
                                class="mr-5"
                                color="primary"
                                @click="exportExcel" :title="$t('save_to_excel')">
                                <v-icon class="mr-2">mdi-microsoft-excel</v-icon>
                            </v-btn>


                        </v-col>
                    </template>
                    <template v-slot:item.name="{ item }">
                        <div :class="item.deleted ? 'red--text' : ''">
                            {{ item.name }}
                            {{ item.deleted ? ' (' + $t('deleted') + ')' : '' }}
                        </div>
                    </template>
                    <template v-slot:item.active="{ item }">
                        {{ item.active ? $t('yes') : $t('no')}}
                    </template>
                    <template v-slot:item.action="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on"  @click="dialogLinkDiscountCardOpen(item)" icon>
                                    <v-icon>mdi-brightness-percent</v-icon>
                                </v-btn>
                            </template>
                            <span>{{$t('link_discount_card')}}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on"  @click="editUser(item)" icon>
                                    <v-icon>mdi-square-edit-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>{{$t('edit')}}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" :disabled="item.deleted" @click="deleteUser(item)" icon>
                                    <v-icon>mdi-delete-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ item.deleted ? $t('remotely') : $t('delete') }}</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-pagination v-model="page" :total-visible="totalVisiblePag"
                              :length="pageCount" :disabled="loading"></v-pagination>
            </v-card-actions>
        </v-card>

        <ValidationObserver ref="observerAdd" v-slot="{ invalid, validated, passes, validate }">
            <v-dialog
                v-model="dialogLinkDiscountCard"
                persistent
                max-width="600px"
            >
                <v-card>
                    <v-card-title>
                        <span class="text-h5">{{$t('link_discount_card')}}</span>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" class="pt-5 pb-0">{{clientAddCard.name}}</v-col>
                            <v-col cols="12" >
                                <ValidationProvider ref="loyalty"  rules="required"
                                                    v-slot="{ errors, valid }">
                                    <v-select v-model="loyalty" :items="loyaltyItems" :disabled="loading"
                                              item-value="id"
                                              item-text="title"
                                              :error-messages="errors"
                                              prepend-icon="mdi-brightness-percent"
                                              :label="$t('discount_card')" clearable></v-select>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="dialogLinkDiscountCardClose()"
                        >

                            {{$t('close')}}
                        </v-btn>
                        <v-btn
                            :disabled="invalid || loading"
                            :loading="loading"
                            color="blue darken-1"
                            text
                            @click="saveClientDiscountCard()"
                        >
                            {{$t('add')}}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </ValidationObserver>

    </v-container>
</template>
<script>

    import { mapGetters } from "vuex"
    import { ValidationObserver, ValidationProvider } from 'vee-validate'
    import { mask } from 'vue-the-mask'

    export default {
        name: "Clients",
        directives: {
            mask,
        },
        components: {
            ValidationProvider,
            ValidationObserver
        },
        data() {
            return {
                fab: false,
                phoneRaw: null,
                client: null,
                email: null,
                sex: null,
                sexItems: [],
                ageFrom: null,
                ageTo: null,
                clients: [],
                clientItems: [],
                active: 1,
                activeItems: [
                    {
                        id: null,
                        name: this.$t('all')
                    },
                    {
                        id: 1,
                        name: this.$t('yes')
                    },
                    {
                        id: 0,
                        name: this.$t('no')
                    }
                ],
                sortBy: "name",
                sortDir: true,
                page: 1,
                pageCount: 0,
                perPage: 5,
                totalVisiblePag: 5,
                totalClients: 0,
                options: {},
                loading: false,
                loadingCities: false,
                city: null,
                cityItems: [],
                citySearching: null,
                headers: [
                    {
                        text: this.$t('client'),
                        align: "left",
                        sortable: true,
                        value: "name"
                    },
                    {
                        text: this.$t('phone'),
                        align: "left",
                        sortable: true,
                        value: "phone"
                    },
                    {
                        text: this.$t('email'),
                        align: "left",
                        sortable: true,
                        value: "email"
                    },
                    {
                        text: this.$t('birthday_date'),
                        align: "left",
                        sortable: true,
                        value: "birthday_at"
                    },
                    {
                        text: this.$t('active'),
                        align: "center",
                        sortable: true,
                        value: "active",
                        width: 110,
                    },
                    {
                        text: this.$t('actions'),
                        align: "center",
                        value: 'action',
                        sortable: false,
                        width: 150,
                    },
                ],
                dialogLinkDiscountCard: false,
                clientAddCard: {},
                loyalty: null,
                loyaltyItems: [],
            };
        },
        computed: {
            ...mapGetters(['lang', 'itemsPerPage', 'perPageItems', 'phoneMask']),
            phone: function () {
                return this.changePhone(this.phoneRaw)
            },
            filteredHeaders() {
                return this.headers.filter(h => !h.hide)
            },
            ageFromItems() {
                let arr = []
                for (let i = 14; i <= (this.ageTo ? this.ageTo : 100); i++) {
                    arr.push(i)
                }
                return arr;
            },
            ageToItems() {
                let arr = []
                for (let i = (this.ageFrom ? this.ageFrom : 14); i <= 100; i++) {
                    arr.push(i)
                }
                return arr;
            },
        },
        mounted() {
            this.getClientDiscountCard();
            this.getSexes();
            this.options.itemsPerPage = this.perPage = this.itemsPerPage
        },
        watch: {
            options: {
                handler() {
                    this.getClients()
                },
                deep: false
            }
        },
        methods: {

            dialogLinkDiscountCardOpen(item){
               this.clientAddCard = item
               this.dialogLinkDiscountCard = true
            },
            dialogLinkDiscountCardClose(){
                this.dialogLinkDiscountCard = false;
                this.clientAddCard = {};
                this.loyalty = null;
            },
            async saveClientDiscountCard() {
                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()
                if (this.loyalty) {
                    formData.append('loyalty_id', this.loyalty)
                }

                await this.$http
                    .put(`admin/client_add_card/${this.clientAddCard.id}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('client_has_been_updated'))
                        this.dialogLinkDiscountCardClose()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('client_has_not_been_updated'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
            async getClientDiscountCard() {
                this.loading = true
                let params = {}


                await this.$http
                    .get("admin/loyalty", {
                        params: params,
                    })
                    .then(res => {
                        this.loyaltyItems = res.body.data
                    })
                    .catch(err => {
                        this.loyaltyItems = []
                        this.$toastr.error(this.$t('failed_to_get_list_loyalties'))
                    })
                    .finally(end => {
                        this.loading = false
                    })
            },
            async getSexes() {
                this.loading = true
                let params = {}


                await this.$http
                    .get("admin/sex", {
                        params: params,
                    })
                    .then(res => {
                        this.sexItems = res.body.data
                    })
                    .catch(err => {
                        this.sexItems = []
                        this.$toastr.error(this.$t('failed_to_get_list_sexes'))
                    })
                    .finally(end => {
                        this.loading = false
                    })
            },
            editUser(item) {
                this.$router.push({
                    name: 'client.edit',
                    params: {
                        id: item.id
                    }
                })
            },
            async deleteUser(item) {
                if (confirm(this.$t('delete_client'))) {
                    var _this = this
                    this.loading = true;
                    await this.$http
                        .delete(`admin/client/${item.id}`)
                        .then(res => {
                            this.$toastr.success(this.$t('client_has_been_deleted'))
                            this.getClients()
                        })
                        .catch(err => {
                            this.$toastr.success(this.$t('client_has_not_been_deleted'))
                            if (err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.loading = false
                        })
                }
            },
            async getClients(type) {
                this.loading = true;
                const {
                    sortBy,
                    sortDesc,
                    page,
                    itemsPerPage
                } = this.options;
                let params = {};
                if (sortBy[0] !== undefined) {
                    params.sortBy = sortBy[0];
                }
                if (sortDesc[0] !== undefined) {
                    params.sortDir = sortDesc[0] ? 'asc' : 'desc';
                }
                if (page !== undefined) {
                    if (type === 'search') {
                        params.page = 1
                    } else {
                        params.page = page
                    }
                }
                if (itemsPerPage !== undefined) {
                    params.perPage = itemsPerPage;
                }
                if (this.client) {
                    params.client = this.client;
                }
                if (this.phone) {
                    params.phone = this.phone;
                }
                if (this.email) {
                    params.email = this.email;
                }

                if (this.sex) {
                    params.sex = this.sex;
                }
                if (this.ageFrom) {
                    params.ageFrom = this.ageFrom;
                }
                if (this.ageTo) {
                    params.ageTo = this.ageTo;
                }
                params.active = this.active

                await this.$http
                    .get("admin/client", {
                        params: params,
                    })
                    .then(res => {
                        this.clients = res.body.data
                        this.page = res.body.meta.current_page
                        this.totalClients = res.body.meta.total
                        this.pageCount = res.body.meta.last_page
                    })
                    .catch(err => {
                        this.clients = []
                        this.totalClients = 0
                        this.$toastr.error(this.$t('failed_to_get_list_clients'))
                    })
                    .finally(end => {
                        this.loading = false
                    })
            },
            async exportExcel(type) {
                this.loading = true;
                const {
                    sortBy,
                    sortDesc,
                    page,
                    itemsPerPage
                } = this.options;
                let params = {};
                if (sortBy[0] !== undefined) {
                    params.sortBy = sortBy[0];
                }
                if (sortDesc[0] !== undefined) {
                    params.sortDir = sortDesc[0] ? 'asc' : 'desc';
                }
                if (page !== undefined) {
                    if (type === 'search') {
                        params.page = 1
                    } else {
                        params.page = page
                    }
                }
                if (itemsPerPage !== undefined) {
                    params.perPage = itemsPerPage;
                }
                if (this.client) {
                    params.client = this.client;
                }
                if (this.phone) {
                    params.phone = this.phone;
                }
                if (this.email) {
                    params.email = this.email;
                }

                if (this.sex) {
                    params.sex = this.sex;
                }
                if (this.ageFrom) {
                    params.ageFrom = this.ageFrom;
                }
                if (this.ageTo) {
                    params.ageTo = this.ageTo;
                }
                params.active = this.active

                await this.$http
                    .get("admin/client/export", {
                        params: params,
                    })
                    .then(res => {
                        this.saveToFile(res.body.data.file)
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('failed_to_get_list_clients'))
                    })
                    .finally(end => {
                        this.loading = false
                    })
            },
            saveToFile(url) {
                let link = document.createElement('a')
                link.setAttribute('href', url)
                link.style.visibility = 'hidden'
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            }

        }
    }
</script>
